"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const React = tslib_1.__importStar(require("react"));
function onMouseOver(strokeOpacity) {
    return (event) => {
        event.currentTarget.style.strokeWidth = "2";
        event.currentTarget.style.strokeOpacity = String(Math.min(strokeOpacity + 0.3, 1));
    };
}
function onMouseOut(strokeOpacity) {
    return (event) => {
        event.currentTarget.style.strokeWidth = "1";
        event.currentTarget.style.strokeOpacity = String(strokeOpacity);
    };
}
function Region({ href, ...props }, ref) {
    const path = (React.createElement("path", { onMouseOver: onMouseOver(Number(props.strokeOpacity)), onMouseOut: onMouseOut(Number(props.strokeOpacity)), ref: ref, ...props }));
    if (href)
        return React.createElement("a", { ...(typeof href === "string" ? { href } : href) }, path);
    return path;
}
exports.default = React.forwardRef(Region);
