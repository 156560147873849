import React from "react";
import { HashLink } from "react-router-hash-link";

const NavLinks = () => {
  return (
    <>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-[#ff7518]"
        smooth
        to="/#about">
        About
      </HashLink>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-[#ff7518]"
        smooth
        to="/#services">
        Services
      </HashLink>
      {/* <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-[#ff7518]"
        to="/">
        Portfolio
      </HashLink> */}
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-[#ff7518]"
        to="/contact#contact">
        Contact Us
      </HashLink>
      {/* <a
        className="text-white bg-[#ff7518] hover:bg-[#ff7518] cursor-pointer inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
        smooth
        target="blank"
        href="https://opto.mtmglobal.tech/auth/demo-register">
        Demo
      </a> */}
    </>
  );
};

export default NavLinks;
