import React from "react";
import img from "../images/web_developer_new.svg";
import { Link } from "react-router-dom";
import VideoComponent from "../pages/VideoComponent";

const Intro = () => {
  return (
    <div
      className="m-auto max-w-6xl p-2 md:p-12 h-5/6"
      id="about"
      style={{ paddingTop: "80px" }}>
      <div
        className="flex flex-col py-8 justify-between text-center lg:flex-col w-full lg:text-left"
        data-aos="fade-up">
        <div className="w-full lg:w-full flex flex-col justify-center lg:order-1">
          <VideoComponent />
        </div>
        <div
          className="w-full lg:w-full flex flex-col justify-center lg:order-2"
          data-aos="zoom-in"
          data-aos-delay="500">
          <h3 className="md:text-4xl text-center my-20 text-2xl text-[#FF7518] font-bold">
            Welcome To MTM Global, Where Creativity Seamlessly Merges With
            Cutting-Edge Technology To Redefine Your Brand's Success.
          </h3>
          <div className="flex justify-center">
            <Link
              to="/contact"
              className="text-white bg-[#FF7518] hover:bg-[#FF7518] inline-flex items-center justify-center px-6 py-2 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
              Contact us
              <svg
                className="w-4 h-4 ml-1 group-hover:translate-x-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"></path>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
