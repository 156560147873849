import React from "react";
import { Link } from "react-router-dom";

const Portfolio = () => {
  return (
    <>
      <div className="my-4 py-4" id="portfolio">
        <h2 className="my-2 text-center text-3xl text-[#FF7518] uppercase font-bold">
          Products
        </h2>
        <div className="flex justify-center">
          <div className="w-24 border-b-4 border-[#FF7518] mb-8"></div>
        </div>

        <div className="px-4" data-aos="fade-down" data-aos-delay="600">
          <div className="flex justify-center">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 max-w-screen-lg">
              {/* First Card */}
              <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                <div className="m-2 text-justify text-sm">
                  <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
                    School Management Portal
                  </h4>
                  <p className="text-md font-medium leading-5 h-auto md:h-48">
                    Our all encompassing School Management Portal is the only
                    solution needed for any institution. The School Management
                    Portal (SMP) is a tool that can help educational
                    institutions of all kinds manage their administrative tasks,
                    automate processes, and streamline communication between
                    teachers, students, parents, and administrators. The
                    software can be used to manage all aspects of school
                    operations, including student enrollment, attendance,
                    grades, schedules.
                  </p>
                  <div className="flex justify-center my-4">
                    {/* <Link
                      to="/get-demo"
                      className="text-white bg-[#FF7518] hover:bg-[#a54c10] inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                      Schedule Demo
                      <svg
                        className="w-4 h-4 ml-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor">
                        <path
                          fillRule="evenodd"
                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Link> */}
                  </div>
                </div>
              </div>

              {/* Second Card */}
              <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                <div className="m-2 text-justify text-sm">
                  <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
                    Project Management System
                  </h4>
                  <p className="text-md font-medium leading-5 h-auto md:h-48">
                  Introducing our cloud-based software solution, designed to revolutionize the way Project Managers handle their projects with the power of AI. What sets it apart? Our advanced task and workflow automation feature! Say goodbye to manual updates and disorganized schedules. With this tool, you can effortlessly track progress, assign tasks, and manage timelines, all in one place. Your projects, streamlined.
                  </p>
                  <div className="flex justify-center my-4">
                    {/* <Link
                      to="/get-demo"
                      className="text-white bg-[#FF7518] hover:bg-[#a54c10] inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                      Schedule Demo
                      <svg
                        className="w-4 h-4 ml-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor">
                        <path
                          fillRule="evenodd"
                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Link> */}
                  </div>
                </div>
              </div>

              {/* Third Card */}
              <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                <div className="m-2 text-justify text-sm">
                  <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
                    Event Management System
                  </h4>
                  <p className="text-md font-medium leading-5 h-auto md:h-48">
                    Our event management system helps to manage different types
                    of events, whether weddings, burials or any kind of event.
                    With an easily customizable menu, you get to define your
                    user experience and make your event fully customizable and
                    memorable. With use cases already recorded for quite a
                    number of events, we can assure you of a stress-less event
                    mangement platform.
                  </p>
                  <div className="flex justify-center my-4">
                    {/* <Link
                      to="/get-demo"
                      className="text-white bg-[#FF7518] hover:bg-[#a54c10] inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                      Schedule Demo
                      <svg
                        className="w-4 h-4 ml-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor">
                        <path
                          fillRule="evenodd"
                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Portfolio;
