import React from "react";

import WorldMap from "react-svg-worldmap";

const highlightedCountries = [
  { country: "US", value: 1 }, // USA
  { country: "CA", value: 1 }, // Canada
  { country: "PK", value: 1 }, // Pakistan
  { country: "AUS", value: 1 }, // Australia
  { country: "AE", value: 1 }, // UAE
  { country: "DE", value: 1 }, // Germany
];

const MapComponent = () => {
  return (
    <div className="flex justify-center items-center ">
      <WorldMap
        color="rgb(255 118 26)"
        title=""
        valueSuffix="selected"
        size="xxl"
        data={highlightedCountries}
      />
    </div>
  );
};

export default MapComponent;
