import React from "react";

const VideoComponent = () => {
  return (
    <div
      style={{
        backgroundColor: "#1a1a1a",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "2%",
      }}>
      <iframe
        width={1000}
        height={500}
        style={{ borderRadius: "10px" }}
        src="https://www.youtube.com/embed/wHVNIaI41hc?autoplay=1&mute=1&si=FTl8qoNs8Dl4nZWd&rel=0"
        title="Meet Our Team!"
        frameBorder="10"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen></iframe>
    </div>
  );
};

export default VideoComponent;
